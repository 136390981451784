<template>
  <div class="page" :style="{'minHeight':cHeight}">
    <div class="top">
      <img src="../assets/about_icon_logo.png" alt="">
      <div>当前版本号：2.0.0</div>
    </div>
    <div class="box">
      <div class="lis" @click="$router.push({path:'/agreement'})">
        <span>用户协议</span>
        <img src="../assets/function_btn_forward.png" alt="">
      </div>
      <div class="lis" @click="$router.push({path:'/privacy'})">
        <span>隐私政策</span>
        <img src="../assets/function_btn_forward.png" alt="">
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        cHeight: null
      }
    },
    mounted() {
      this.cHeight = (document.documentElement.clientHeight || document.body.clientHeight) + 'px'

    },
    methods: {

    }
  }

</script>

<style scoped lang='scss'>
  .page {
    background: #F5F5F8;
  }

  .top {
    padding-top: 80px;
    height: 300px;
    text-align: center;
    background: #fff;
    font-size: 24px;
    color: #333333;

    img {
      margin-bottom: 42px;
      width: 224px;
      height: 80px;
    }
  }

  .box {
    margin-top: 20px;
    background: #fff;
  }

  .lis {
    display: flex;
    padding-right: 30px;
    height: 100px;
    justify-content: space-between;
    align-items: center;
    margin-left: 30px;
    border-bottom: 1px solid #F5F5F8;
    font-size: 26px;
    color: #333333;

    img {
      width: 8px;
      height: 22px;
    }
  }

</style>
